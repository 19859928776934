import { useMemo } from 'react';
import { useQuery } from '@apollo/client';

import { OrderingEnum } from 'api/ordering/types';
import { useGetOrdering } from 'api/ordering/useGetOrdering';
import { useCanAdministrateSearches } from 'hooks/useCheckUserRight';
import {
  MemberTypeEnum,
  ScopedConfig,
  ScopedConfigEntity,
  VisiblePlaces,
} from 'types/graphqlTypes';
import { sortByOrdering } from 'utils/sortByOrder';

import { GET_SCOPED_CONFIGS } from './gql';

export interface GetScopedConfigs {
  getScopedConfigs: ScopedConfigEntity[];
}

export const toScopedConfigDto = <T>(s: ScopedConfigEntity): ScopedConfig<T> => {
  try {
    return {
      ...s,
      visibleIn: s.visibleIn ?? [],
      visibleTo: s.visibleTo ?? [],
      data: JSON.parse(s.data ?? '{}') as T,
    };
  } catch (err) {
    return {
      ...s,
      visibleIn: s.visibleIn ?? [],
      visibleTo: s.visibleTo ?? [],
      data: {} as T,
    };
  }
};

export const locationToOrderingMap: Record<VisiblePlaces, OrderingEnum> = {
  rundown_layouts: OrderingEnum.rundownLayouts,
  left_sidebar: OrderingEnum.savedSearchLeftSideBar,
  contacts_sidebar: OrderingEnum.savedSearchContacts,
};

export const useGetScopedConfigs = <T>(
  orderingToFetch: OrderingEnum,
  mType: MemberTypeEnum,
  location?: VisiblePlaces,
) => {
  const { canAdministrate } = useCanAdministrateSearches();

  const { ordering, loading: orderingLoading } = useGetOrdering(orderingToFetch);
  const { data, error, loading, refetch } = useQuery<GetScopedConfigs>(GET_SCOPED_CONFIGS, {
    fetchPolicy: 'cache-and-network',
    variables: {
      input: {
        all: canAdministrate,
        mType,
        location,
      },
    },
  });

  const scopedConfigs = useMemo(() => {
    if (orderingLoading || loading) return [];
    const items = data?.getScopedConfigs?.map((e) => toScopedConfigDto<T>(e)) ?? [];
    return sortByOrdering<ScopedConfig<T>>(items, ordering?.order || []);
  }, [data, ordering, orderingLoading, loading]);

  return {
    scopedConfigs,
    error,
    loading,
    refetch,
  };
};
