import { css, Theme } from '@emotion/react';
import styled from '@emotion/styled/macro';

import PitchImageSrc from 'assets/images/pitchBackground.svg';
import archivedStorySrc from 'assets/images/Tile_ArchivedStory.png';
import TileImage from 'assets/images/Tile_DiagonalPattern.png';
import { IconButton } from 'components/buttons';
import Divider from 'components/divider';
import Text from 'components/text/Text';
import { ContentTab, isContentType } from 'store/tabs';
import transientOptions from 'theme/helpers';

const commonDividerStyles = ({ theme }: { theme: Theme }) => css`
  content: '';
  position: absolute;
  margin: auto 0;
  height: 24px;
  width: 1px;
  margin-bottom: 4px;
  background-color: ${theme.palette.dina.dividerLight};
`;

export const StyledTabIconWrapper = styled('div')`
  margin-right: 4px;
  width: 24px;
  height: 24px;
  svg {
    width: 24px;
    height: 24px;
  }
`;

export const StyledLabel = styled('div')`
  flex: 1;
  display: flex;
  flex-direction: column;
  min-width: 0;
  text-align: left;
`;

export const StyledTabTitle = styled(Text)`
  user-select: none;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;
StyledTabTitle.defaultProps = {
  variant: 'storyTab',
  color: 'whiteMediumEmphasis',
};

export const StyledTabSubtitle = styled(Text)`
  user-select: none;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;
StyledTabSubtitle.defaultProps = {
  variant: 'caption',
  color: 'whiteMediumEmphasis',
};

export const StyledIconButton = styled(IconButton)`
  svg path {
    fill: ${({ theme }) => theme.palette.dina.whiteHighEmphasis};
    fill-opacity: 0.54;
  }

  :hover svg {
    path {
      fill: ${({ theme }) => theme.palette.dina.whiteHighEmphasis};
      fill-opacity: 1;
    }
  }
`;

export const StyledTabContentWrapper = styled('div', transientOptions)<{
  width: number;
  $tab: ContentTab;
  $isSelected: boolean;
  $hovered: boolean;
  $isSubRundown: boolean;
  $isTodayForRundownTab?: boolean;
}>`
  cursor: pointer;
  z-index: 1;
  border-radius: 8px 8px 0 0;
  min-height: 42px;
  min-width: 146px;
  max-width: 240px;
  padding-inline: 8px 4px;
  display: flex;
  align-items: center;
  position: relative;

  :last-of-type::after {
    ${commonDividerStyles}
    right: 0;
  }

  ::before {
    ${commonDividerStyles}
    left: 0;
  }

  :hover {
    ::before {
      display: none;
    }

    ::after {
      display: none;
    }
  }

  :hover + & {
    ::before {
      display: none;
    }
  }

  width: ${({ width }) => `${width ?? 240}px`};
  ${({ $hovered, theme }) => css`
    border: 1px solid ${$hovered ? theme.palette.dina.statusWarning : 'transparent'};
    svg {
      path {
        fill: ${theme.palette.dina.whiteHighEmphasis};
        fill-opacity: ${theme.palette.mode === 'light' ? 0.65 : 0.54};
      }
    }
  `}
  :hover {
    ${({ $isSelected, theme }) =>
      !$isSelected &&
      css`
        background-color: ${theme.palette.dina.whiteHoverOverlay};
        color: ${theme.palette.dina.highEmphasis};
        ${StyledTabIconWrapper} {
          svg {
            path {
              fill: ${theme.palette.dina.iconSelectedWhite};
              fill-opacity: 1;
            }
          }
        }
        ${StyledTabTitle},
        ${StyledTabSubtitle} {
          color: ${theme.palette.dina.whiteHighEmphasis} !important;
        }
        ::before {
          display: none;
        }

        ::after {
          display: none;
        }
      `}
  }

  ${({ $tab, $isSelected, $isSubRundown, theme, $isTodayForRundownTab }) => {
    if ($isSelected && $tab) {
      if (!isContentType($tab.type)) {
        return css`
          background-color: ${theme.palette.dina.primary700};
          color: ${theme.palette.dina.highEmphasis};
        `;
      }
      if ($tab.type === 'pitch') {
        return css`
          background-color: #323e4d;
          background-image: url(${PitchImageSrc});
          background-position-x: 24%;
        `;
      }
      if ($tab?.type === 'space') {
        return css`
          background: ${theme.palette.dina.statusSpace};
        `;
      }
      if ($tab?.type === 'rundowntemplate') {
        return css`
          color: ${theme.palette.dina.blackHighEmphasis};
          background-color: ${$isSubRundown
            ? theme.palette.dina.paletteAccentDarkOrange
            : theme.palette.dina.paletteAccentOrange};
        `;
      }

      if ($tab.type === 'story' && $tab.archived) {
        return css`
          background-color: transparent;
          background-image: url(${archivedStorySrc});
          background-size: 14px;
        `;
      }
      if ($tab?.type !== 'rundown') {
        return css`
          background: ${theme.palette.dina.onSelected};
        `;
      }
      return $isTodayForRundownTab
        ? css`
            background-color: ${$isSubRundown
              ? theme.palette.dina.paletteAccentDarkRed
              : theme.palette.dina.paletteAccentPurple};
          `
        : css`
            background-color: ${$isSubRundown
              ? theme.palette.dina.paletteAccentDarkRed
              : theme.palette.dina.paletteAccentPurple};
            background-image: url(${TileImage});
          `;
    }
    return undefined;
  }}

  ${({ $isSelected, theme }) => {
    return (
      $isSelected &&
      css`
        ${StyledTabIconWrapper} {
          svg {
            path {
              fill: ${theme.palette.dina.iconSelectedWhite};
              fill-opacity: 1;
            }
          }
        }
        ${StyledTabTitle},
        ${StyledTabSubtitle} {
          color: ${theme.palette.dina.whiteHighEmphasis} !important;
        }
        ::before {
          display: none;
        }

        ::after {
          display: none;
        }
      `
    );
  }}
`;

export const StyledTabDivider = styled(Divider)`
  height: 100%;
`;

export const NotificationWrapper = styled.div`
  position: relative;
  left: -10px;
  top: -10px;
  width: 0;
`;
