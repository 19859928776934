/* eslint-disable import/prefer-default-export */
import { css } from '@emotion/react';
import styled from '@emotion/styled';

import PitchImageSrc from 'assets/images/pitchBackground.svg';
import TileImage from 'assets/images/Tile_DiagonalPattern.png';
import { type ContentTab, type IconTab, isContentType } from 'store';
import transientOptions from 'theme/helpers';

export const StyledTabs = styled('div', transientOptions)<{
  $tab: IconTab | ContentTab;
  $isTodayForRundownTab?: boolean;
  $isSubRundown?: boolean;
}>`
  flex: 1;
  min-height: 44px;
  padding-top: 4px;
  display: flex;

  .MuiTabs-indicator {
    transition: none;
    height: 100%;
    border-radius: 8px 8px 0 0;
    ${({ $tab, $isSubRundown, $isTodayForRundownTab, theme }) => {
      if ($tab) {
        if (!isContentType($tab.type)) {
          return css`
            background-color: ${theme.palette.dina.primary700};
            color: ${theme.palette.dina.highEmphasis};
          `;
        }
        if ($tab.type === 'pitch') {
          return css`
            background-color: #323e4d;
            background-image: url(${PitchImageSrc});
            background-position-x: 24%;
          `;
        }
        if ($tab?.type === 'space') {
          return css`
            background: ${theme.palette.dina.statusSpace};
          `;
        }
        if ($tab?.type === 'rundowntemplate') {
          return css`
            color: ${theme.palette.dina.blackHighEmphasis};
            background-color: ${$isSubRundown
              ? theme.palette.dina.paletteAccentDarkOrange
              : theme.palette.dina.paletteAccentOrange};
          `;
        }
        if ($tab?.type !== 'rundown') {
          return css`
            background: ${theme.palette.dina.onSelected};
          `;
        }
        return $isTodayForRundownTab
          ? css`
              background-color: ${$isSubRundown
                ? theme.palette.dina.paletteAccentDarkRed
                : theme.palette.dina.paletteAccentPurple};
            `
          : css`
              background-color: ${$isSubRundown
                ? theme.palette.dina.paletteAccentDarkRed
                : theme.palette.dina.paletteAccentPurple};
              background-image: url(${TileImage});
            `;
      }
      return undefined;
    }}
  }
`;
