import { useCallback } from 'react';
import { useMutation } from '@apollo/client';

import { DeleteScopedConfigInput, MemberTypeEnum } from 'types/graphqlTypes';

import { DELETE_SCOPED_CONFIG } from './gql';
import { removeFromCache } from './handleCacheUpdate';

interface DeleteScopedConfig {
  deleteScopedConfig: DeleteScopedConfigInput;
}

export const useDeleteScopedConfig = (canAdministrate: boolean) => {
  const [doDelete] = useMutation<DeleteScopedConfig>(DELETE_SCOPED_CONFIG);
  const deleteScopedConfig = useCallback(
    async (id: string, type: 'shared' | 'private', mType: MemberTypeEnum) => {
      await doDelete({
        variables: { input: { id, type, mType } },
        update: (proxy) => {
          removeFromCache(proxy, id, mType, canAdministrate);
        },
      });
    },
    [doDelete],
  );

  return { deleteScopedConfig };
};
