import React from 'react';
import { useDrag } from 'react-dnd';
import { format } from 'date-fns';

import ICONS from 'assets/icons/icons';
import { publishingPoints } from 'assets/icons/publishingPoints';
import fallbackImage from 'assets/images/default/defaultThumbnail.png';
import { Button } from 'components/buttons';
import Icon from 'components/icon';
import Tooltip from 'components/tooltip';
import useDinaNavigate from 'hooks/useDinaNavigate';
import useGetRundownTimings from 'hooks/useGetRundownTimings';
import useImageUrl from 'hooks/useImageUrl';
import { getLocalTimezone } from 'hooks/useTimezoneSettings';
import { useCurrentTabId } from 'store/tabs';
import { DateRange } from 'types';
import { MemberTypeEnum, Rundown } from 'types/graphqlTypes';
import dndTypes from 'utils/dndTypes';
import { formatDuration } from 'utils/formatDuration';
import { onImageLoadingError } from 'utils/image/imageUtils';

import {
  ContentWrapper,
  Image,
  ImageWrapper,
  ListItem,
  PlatformImage,
  RundownTitle,
  TimingInfo,
  TimingInfoWrapper,
  TimingLabel,
  TimingValue,
} from './styled';

interface ListItemProps {
  rundown: Rundown;
  showSelected: boolean;
  selectedDates?: DateRange;
  initializeRundown?: (payload: { mId: string; mTitle: string; selectedDate: string }) => void;
  setPreviewRundown?: (payload: { id: string; title: string }) => void;
  setOpenSecondRundown?: (id: string) => void;
}

const safeConvertStringToNumber = (value: string | undefined) => {
  const num = Number(value);
  return isNaN(num) ? 0 : num;
};

const getPublishingAtTime = (rundown: Rundown) => {
  if (rundown.mType === MemberTypeEnum.Rundowntemplate) {
    return rundown.recurrence?.startTime ?? '00:00:00';
  }
  if (!rundown.mPublishingAt) return '00:00:00';
  return format(rundown.mPublishingAt, 'HH:mm:ss');
};

const getPlannedDuration = (rundown: Rundown) => {
  if (rundown.mType === MemberTypeEnum.Rundowntemplate) {
    return rundown.recurrence?.duration ?? '0m';
  }
  return rundown.mPlannedDuration
    ? formatDuration(safeConvertStringToNumber(rundown.mPlannedDuration))
    : '0m';
};

const getPublishingAtDate = (rundown: Rundown) => {
  if (!rundown.mPublishingAt) return '1970-01-01';
  return format(rundown.mPublishingAt, 'E. d MMM. yyyy');
};

const RundownListItem = ({
  rundown,
  selectedDates,
  setPreviewRundown,
  initializeRundown,
  setOpenSecondRundown,
  showSelected,
}: Readonly<ListItemProps>) => {
  const [{ isDragging }, drag] = useDrag({
    type: dndTypes.RUNDOWN,
    item: rundown,
    collect: (monitor) => ({ isDragging: monitor.isDragging() }),
  });

  const { navigateTo } = useDinaNavigate();
  const [currentTabId] = useCurrentTabId();
  const { getRundownPublishingTime } = useGetRundownTimings();
  const image = useImageUrl(rundown.mThumbnailKey ?? '');
  const activeTab = showSelected && rundown.mId === currentTabId;
  const isAudio = rundown.platformKind === 'audio';
  const startTime = getPublishingAtTime(rundown);

  const handleOpenRundown = () => {
    if (initializeRundown) {
      const selectedDate = getRundownPublishingTime(
        selectedDates?.startDate ?? new Date(),
        startTime,
        rundown?.recurrence?.timeZone ?? getLocalTimezone(),
      );
      initializeRundown({ mTitle: rundown.mTitle!, mId: rundown.mId, selectedDate });
      return;
    }
    navigateTo(
      rundown.mType === MemberTypeEnum.Rundown ? 'rundown' : 'rundowntemplate',
      rundown.mId,
    );
  };

  const handleIconClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    e.stopPropagation();
    if (setOpenSecondRundown) {
      console.log('setting', rundown.mId);
      setOpenSecondRundown(rundown.mId);
      return;
    }
    setPreviewRundown?.({
      id: rundown.mId,
      title: rundown.mTitle ?? '',
    });
  };

  return (
    <ListItem
      button
      selected={activeTab}
      onClick={handleOpenRundown}
      ref={drag}
      $isDragging={isDragging}
    >
      <ImageWrapper>
        <Image
          state={rundown.mState}
          onError={onImageLoadingError}
          src={image ?? fallbackImage}
          alt="thumb"
        />

        {isAudio ? <PlatformImage src={publishingPoints.audio} alt="audio" /> : null}
      </ImageWrapper>
      <ContentWrapper>
        <Tooltip title={rundown.mTitle}>
          <RundownTitle>{rundown.mTitle}</RundownTitle>
        </Tooltip>
        <TimingInfoWrapper>
          <TimingInfo>
            <TimingLabel>IN</TimingLabel>
            <Tooltip title={getPublishingAtDate(rundown)}>
              <TimingValue>{startTime}</TimingValue>
            </Tooltip>
          </TimingInfo>
          <TimingInfo>
            <TimingLabel>PLANNED</TimingLabel>
            <TimingValue>{getPlannedDuration(rundown) ?? '--:--:--'}</TimingValue>
          </TimingInfo>
        </TimingInfoWrapper>
      </ContentWrapper>
      {setPreviewRundown && (
        <Button
          title={setOpenSecondRundown ? 'Open side by side' : 'Preview Rundown'}
          onClick={handleIconClick}
          variant="discreet"
          usage="text"
          size="icon"
        >
          <Icon icon={ICONS.DISCLOSURE_ARROW_RIGHT} />
        </Button>
      )}
    </ListItem>
  );
};

export default RundownListItem;
