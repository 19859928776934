export enum OrderingEnum {
  savedSearchCommandbar = 'savedSearchCommandbar',
  savedSearchContacts = 'savedSearchContacts',
  savedSearchLeftSideBar = 'savedSearchLeftSideBar',
  rundownLayouts = 'rundownLayouts',
  defaultSnippet = 'defaultSnippetOrder', // revisit to store order per editor location
}

export type Ordering = {
  id: string;
  type: OrderingEnum;
  order: string[];
};
