import { css } from '@emotion/react';
import styled from '@emotion/styled';
import AppBar from '@material-ui/core/AppBar';

import pitchToolbarSrc from 'assets/images/pitchBackground.svg';
import archivedStoryToolbarSrc from 'assets/images/Tile_ArchivedStory.png';
import rndToolbarSrc from 'assets/images/Tile_DiagonalPattern.png';
import { Content } from 'store';
import transientOptions from 'theme/helpers';

interface AppBarProps {
  $height?: number;
  $isStory: boolean;
  $isToday: boolean;
  $mType?: Content;
  $isArchived?: boolean;
  $isSubRundown?: boolean;
}

export const StyledAppBar = styled(AppBar, transientOptions)<AppBarProps>`
  height: ${({ $height }) => `${$height ?? 44}px`};
  ${({ theme }) => css`
    box-shadow: 0px 4px 4px ${theme.palette.dina.boxShadowDark},
      0px 0px 1px ${theme.palette.dina.boxShadowDark};
  `}
  ${({ $mType, $isSubRundown, $isStory, $isToday, $isArchived, theme }) => {
    if ($mType === 'rundown') {
      return css`
        background-color: ${$isSubRundown
          ? theme.palette.dina.paletteAccentDarkRed
          : theme.palette.dina.paletteAccentPurple};
        background-image: ${$isToday ? null : `url(${rndToolbarSrc})`};
      `;
    }
    if ($mType === 'rundowntemplate') {
      return css`
        background-color: ${$isSubRundown
          ? theme.palette.dina.paletteAccentDarkOrange
          : theme.palette.dina.paletteAccentOrange};
      `;
    }
    if ($mType === 'create')
      return css`
        background-color: ${theme.palette.dina.onSelected};
      `;
    if ($isStory)
      return $isArchived
        ? css`
            background: url(${archivedStoryToolbarSrc});
            background-size: 14px 14px;
            background-color: transparent;
          `
        : css`
            background-color: ${theme.palette.dina.onSelected};
          `;
    if ($mType === 'pitch') {
      return css`
        background-color: #323e4d;
        background-image: url(${pitchToolbarSrc});
      `;
    }
    if ($mType === 'space')
      return css`
        background-color: ${theme.palette.dina.statusSpace}};
      `;

    return css`
      background-color: ${theme.palette.dina.primary700};
    `;
  }}
`;

export const StyledToolbar = styled('div', transientOptions)<{ $height: number }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: ${({ $height }) => `${$height}px`};
  padding: 0 12px;
`;
