import { useCallback, useState } from 'react';
import { useMutation } from '@apollo/client';

import useToast from 'components/toast/useToast';
import { CreateScopedConfig, ScopedConfigEntity } from 'types/graphqlTypes';

import { CREATE_SCOPED_CONFIG } from './gql';
import { writeToCache } from './handleCacheUpdate';

interface ICreateScopedConfig {
  createScopedConfig: ScopedConfigEntity;
}

export const useCreateScopedConfig = (location: string | undefined, canAdministrate: boolean) => {
  const { errorToast } = useToast();
  const [loading, setLoading] = useState(false);
  const [createMutation] = useMutation<ICreateScopedConfig>(CREATE_SCOPED_CONFIG);

  const createScopedConfig = useCallback(
    async (input: CreateScopedConfig) => {
      try {
        setLoading(true);
        await createMutation({
          variables: {
            input,
          },
          update: (proxy, mutationResult) => {
            const newEntity = mutationResult.data?.createScopedConfig;
            if (newEntity) writeToCache(proxy, newEntity, input.mType, canAdministrate);
          },
        });
      } catch (err) {
        errorToast(err);
      } finally {
        setLoading(false);
      }
    },
    [createMutation, location],
  );
  return { createScopedConfig, loading };
};
