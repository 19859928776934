import React from 'react';
import { CSS } from '@dnd-kit/utilities';

import { ReactComponent as Delete } from 'assets/icons/systemicons/delete.svg';
import { ReactComponent as EditIcon } from 'assets/icons/systemicons/edit_alt.svg';
import { RenderItemProps } from 'components/sortableList';
import Tooltip from 'components/tooltip';
import { ColorStripe } from 'features/reusableStyled';
import { HStack } from 'layouts/box/Box';
import { ScopedConfig } from 'types/graphqlTypes';

import { Actions, ScopedConfigLabelText, StyledHStack, SubText } from './styled';

interface ScopedConfigItemProps<T> {
  item: ScopedConfig<T>;
  selected: boolean;
  parentSize: string;
  onClick: () => void;
  onKeyDown: (ev: React.KeyboardEvent<HTMLElement>) => void;
  onDeleteClick: (item: ScopedConfig<T>) => void;
  onEditClick?: (item: ScopedConfig<T>) => void;
}

const getLabel = (type: 'shared' | 'private', userGroups?: string[]) => {
  if (type === 'private') return 'Private';
  return !userGroups?.length ? 'Everyone' : 'Shared';
};

export default function ScopedConfigItem<T>({
  item,
  selected,
  onClick,
  onKeyDown,
  onDeleteClick,
  onEditClick,
  setNodeRef,
  parentSize,
  listeners,
  transform,
  transition,
  attributes,
}: Readonly<ScopedConfigItemProps<T> & RenderItemProps>) {
  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <StyledHStack
      justifyContent="start"
      alignItems="center"
      width="100%"
      onClick={onClick}
      $selected={selected}
      onKeyDown={onKeyDown}
      ref={setNodeRef}
      style={style}
      gap={parentSize === 'small' ? '6px' : '8px'}
      {...attributes}
      {...listeners}
    >
      {item.color ? (
        <ColorStripe $color={item.color} style={{ marginTop: '1px' }} />
      ) : (
        <div style={{ width: '4px' }} />
      )}
      <HStack justifyContent="space-between" width="100%" gap="4px">
        <ScopedConfigLabelText variant="caption" $small={parentSize === 'small'}>
          {item.label}
        </ScopedConfigLabelText>
        {parentSize === 'max' && (
          <SubText className="extra-info" variant="caption">
            {getLabel(item.type, item.visibleTo)}
          </SubText>
        )}
        <Actions className="actions">
          {onEditClick && (
            <Tooltip title="Edit">
              <EditIcon
                onClick={(ev) => {
                  ev.stopPropagation();
                  onEditClick(item);
                }}
              />
            </Tooltip>
          )}
          <Tooltip title="Delete">
            <Delete
              onClick={(ev) => {
                ev.stopPropagation();
                onDeleteClick(item);
              }}
            />
          </Tooltip>
        </Actions>
      </HStack>
    </StyledHStack>
  );
}
