import { capitalize } from 'lodash';

import variants from 'components/editor/constants/types/editorVariants';
import { EditorVariant } from 'components/editor/types';

const supportedVariants: EditorVariant[] = [
  'cms',
  'dailyNote',
  'general',
  'linear',
  'notes',
  'script',
];

export const supportedSnippetLocations = Object.values(variants).filter((v) =>
  supportedVariants.includes(v),
);

const getLabelFromVariant = (v: EditorVariant) => {
  if (v === 'general') return 'Content';
  if (v === 'dailyNote') return 'Daily note';
  if (v === 'notes') return 'Research notes';
  return capitalize(v);
};

export const snippetLocations = supportedSnippetLocations.map((s) => ({
  type: s,
  label: getLabelFromVariant(s),
}));
