/* eslint-disable react/display-name */
import {
  Fragment,
  memo,
  MouseEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { BaseSelection, Editor, Range, Transforms } from 'slate';
import { ReactEditor, useFocused, useSlate } from 'slate-react';

import { useGetAiPrompts } from 'api/config/useGetAiPrompts';
import { ReactComponent as AddIcon } from 'assets/icons/systemicons/add_small.svg';
import { ReactComponent as DropdownIcon } from 'assets/icons/systemicons/arrows/arrow_drop_down_discreet_unboxed.svg';
import { useAddInstanceDialog } from 'components/addInstanceDialog/useAddInstanceDialog';
import { Button } from 'components/buttons';
import { IconButton } from 'components/buttons/IconButton';
import { StyledCreateButton } from 'components/createStoryItems/styled';
import Divider from 'components/divider';
import ScopedSnippetsDialog, {
  useScopedSnippetsDialog,
} from 'components/editor/components/snippets/SnippetDialog';
import { EditorVariant } from 'components/editor/types';
import extractText from 'components/editor/utils/getAllTextFromNodes';
import movePortalToViewPort from 'components/editor/utils/movePortalToViewPort';
import stopAllPropagation from 'components/editor/utils/stopAllPropagation';
import { CloseIcon } from 'components/orderFormDialog/styled';
import Popover from 'components/popover';
import Portal from 'components/portal';
import Text from 'components/text/Text';
import Tooltip from 'components/tooltip/Tooltip';
import useCheckUserRight from 'hooks/useCheckUserRight';
import useContainerWidth from 'hooks/useContainerWidth';
import getTime from 'screens/rundown/components/editor/utils/getTime';
import { CustomElement } from 'types';
import accessibleOnClick from 'utils/accessibleOnClick';

import { useEditorMolecule } from '../../store';
import getWords from '../../utils/getWords';

import AskAI from './AskAI';

import {
  AIButton,
  AIContainer,
  AIIcon,
  Container,
  PinOffIcon,
  PinOnIcon,
  PromptsContainer,
  PromptsDropDownContainer,
  SnippetsIcon,
  TextButton,
} from './styled';

const anchorOrigin = {
  vertical: 'bottom',
  horizontal: 'right',
};

const transformOrigin = {
  vertical: 'top',
  horizontal: 'right',
};

const getWordCount = (nodes: CustomElement[]) => getWords(nodes, true).length;

const TooltipWrapper = memo(
  ({ inPortal, children }: { inPortal: boolean; children: React.ReactNode }) => {
    if (inPortal) return <Portal>{children}</Portal>;
    return <>{children}</>;
  },
);

interface HoveringTooltipProps {
  hostReadSpeed: number;
  writeLock: boolean;
  isPinned: boolean;
  togglePin: () => void;
  containerRef: React.RefObject<HTMLDivElement>;
  editorVariant: EditorVariant;
}

function HoveringTooltip({
  hostReadSpeed,
  writeLock,
  isPinned,
  togglePin,
  containerRef,
  editorVariant,
}: Readonly<HoveringTooltipProps>) {
  const ref = useRef<HTMLDivElement>(null);
  const editor = useSlate();
  const inFocus = useFocused();
  const { prompts } = useGetAiPrompts();
  const [checkUserRight] = useCheckUserRight();
  const [, setAddInstance] = useAddInstanceDialog();
  const { useShowHoveringToolbar } = useEditorMolecule();
  const [, setOpenSnippetsDialog] = useScopedSnippetsDialog();

  const [textReadSpeed, setTextReadSpeed] = useState('0');
  const [isAsking, setIsAsking] = useState(false);
  const [question, setQuestion] = useState('');
  const portalRef = useRef<HTMLDivElement | null>(null);
  const selectionRef = useRef<BaseSelection | null>(null);
  const [showHoveringToolbar, setShowHoveringToolbar] = useShowHoveringToolbar();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const canUseAI = checkUserRight('feature', 'ChatGPT');
  const canUseSnippets = checkUserRight('feature', 'snippets');
  const selectedText = window?.getSelection()?.toString();

  const { selection, children } = editor;
  const [toolbarWidth, setToolBarWidth] = useState<number>(0);

  const editorWidth = useContainerWidth(containerRef);
  const allText = extractText(children as CustomElement[]);
  useEffect(() => {
    if (ref.current) {
      setToolBarWidth(ref.current.offsetWidth);
    }
  }, [isPinned, editorWidth]);

  const handleDropDownClick = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  }, []);

  const handleDropDownClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleSnippetsDropDownClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
      event.stopPropagation();
      setAnchorEl(null);
      setOpenSnippetsDialog({
        openDialog: true,
        currentLocation: editorVariant,
      });
    },
    [setOpenSnippetsDialog],
  );

  const handleTogglePin = () => {
    togglePin();
    setAnchorEl(null);
  };

  const maxVisiblePrompts = useMemo(() => {
    if (!toolbarWidth) return 0;
    if (!isPinned) return 3;
    return Math.floor(toolbarWidth / 150);
  }, [toolbarWidth, isPinned]);

  const doDismiss = (e?: MouseEvent<HTMLButtonElement>) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    const el = portalRef.current;
    if (el) el.removeAttribute('style');
  };

  const onDone = () => {
    setQuestion('');
    setAnchorEl(null);
    setIsAsking(false);
  };

  useEffect(() => {
    if (!showHoveringToolbar) {
      doDismiss();
      setShowHoveringToolbar(true);
    }
  }, [setShowHoveringToolbar, showHoveringToolbar]);

  useEffect(() => {
    const el = portalRef.current;
    if (!el) return;

    if (
      !selection ||
      (!anchorEl && !inFocus) ||
      Range.isCollapsed(selection) ||
      Editor.string(editor, selection) === ''
    ) {
      el.removeAttribute('style');
      setTextReadSpeed('00:00');
      return;
    }

    const nodes = Editor.fragment(editor, selection) as CustomElement[];

    const wordCount = getWordCount(nodes);
    const wordsPerSecond = hostReadSpeed / 60;
    const speakDuration = Math.ceil(wordCount / wordsPerSecond);
    setTextReadSpeed(getTime(speakDuration));

    movePortalToViewPort(el);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedText, selection, inFocus, hostReadSpeed, anchorEl]);

  const doCreate = (ev: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(null);
    setShowHoveringToolbar(false);
    stopAllPropagation(ev);
    selectionRef.current = selection;
    setAddInstance({
      text: selectedText ?? null,
      onCancel: () => {
        if (selectionRef.current) {
          ReactEditor.focus(editor as ReactEditor);
          Transforms.select(editor, selectionRef.current);
          selectionRef.current = null;
        }
      },
    });
  };

  if (!isPinned && isAsking)
    return (
      <AskAI
        question={question}
        canReplace={writeLock}
        isAsking={isAsking}
        selection={selection}
        editor={editor}
        selectedText={selectedText ?? ''}
        onDone={onDone}
        setAddInstance={setAddInstance}
      />
    );
  return (
    <TooltipWrapper inPortal={!isPinned}>
      <Container
        ref={portalRef}
        onMouseDown={(e) => {
          // prevent toolbar from taking focus away from editor
          e.preventDefault();
        }}
        $pinned={isPinned}
      >
        <AIContainer $pinned={isPinned}>
          <Tooltip title="Create instance from text">
            <StyledCreateButton
              aria-haspopup="true"
              aria-owns="create-menu"
              onClick={doCreate}
              $notAllowed={false}
              $size={24}
              $margin="0px"
            >
              <AddIcon />
            </StyledCreateButton>
          </Tooltip>
          <Divider orientation="vertical" flexItem />

          {canUseAI && (
            <>
              <AIButton {...accessibleOnClick(() => setIsAsking(true))}>
                <AIIcon className="skipOverride" />
                Ask AI
              </AIButton>
              <Divider orientation="vertical" flexItem />
              {prompts.length > 0 && (
                <PromptsContainer ref={ref}>
                  {(prompts.length > maxVisiblePrompts
                    ? prompts.slice(0, maxVisiblePrompts)
                    : prompts
                  ).map((prompt) => (
                    <Fragment key={prompt.id}>
                      <TextButton
                        title={prompt.label}
                        onClick={() => {
                          setQuestion(prompt.value);
                          setIsAsking(true);
                          setAnchorEl(null);
                        }}
                      >
                        {prompt.label}
                      </TextButton>
                      <Divider orientation="vertical" flexItem />
                    </Fragment>
                  ))}
                  {prompts.length > maxVisiblePrompts && (
                    <Button usage="text" width={50} height={32} onClick={handleDropDownClick}>
                      +{prompts.length - maxVisiblePrompts}
                      <DropdownIcon className="skipOverride" />
                    </Button>
                  )}

                  <Popover
                    open={!!anchorEl}
                    onClose={handleDropDownClose}
                    anchorEl={anchorEl}
                    anchorOrigin={anchorOrigin}
                    transformOrigin={transformOrigin}
                  >
                    <PromptsDropDownContainer>
                      {(prompts.length > maxVisiblePrompts
                        ? prompts.slice(maxVisiblePrompts, prompts.length)
                        : prompts
                      ).map((prompt) => (
                        <div key={prompt.id}>
                          <TextButton
                            title={prompt.label}
                            onClick={() => {
                              setQuestion(prompt.value);
                              setIsAsking(true);
                              setAnchorEl(null);
                            }}
                          >
                            {prompt.label}
                          </TextButton>
                        </div>
                      ))}
                    </PromptsDropDownContainer>
                  </Popover>
                </PromptsContainer>
              )}
            </>
          )}

          <Text variant="listItemLabel" color="highEmphasis" text-align="left">
            {textReadSpeed}
          </Text>

          {canUseSnippets && (
            <>
              <Divider orientation="vertical" flexItem />
              <IconButton
                title="Create a snippet from selection"
                onClick={handleSnippetsDropDownClick}
              >
                <SnippetsIcon className="skipOverride" />
              </IconButton>
            </>
          )}
          {canUseAI && (
            <>
              <Divider orientation="vertical" flexItem />
              <IconButton
                title={`${isPinned ? 'Unpin from' : 'Pin in'} toolbar`}
                onClick={handleTogglePin}
              >
                {isPinned ? <PinOffIcon /> : <PinOnIcon className="skipOverride" />}
              </IconButton>
            </>
          )}
          {!isPinned && (
            <>
              <Divider variant="fullWidth" orientation="vertical" />
              <IconButton title="Dismiss" onClick={doDismiss}>
                <CloseIcon />
              </IconButton>
            </>
          )}
        </AIContainer>
      </Container>

      {isPinned && isAsking && (
        <AskAI
          question={question}
          canReplace={writeLock}
          isAsking={isAsking}
          selection={selection}
          editor={editor}
          selectedText={selectedText ? selectedText : allText}
          onDone={onDone}
          setAddInstance={setAddInstance}
        />
      )}
      {canUseSnippets && <ScopedSnippetsDialog />}
    </TooltipWrapper>
  );
}

export default memo(HoveringTooltip);
