/* eslint-disable max-len */
import { useCallback, useMemo, useState } from 'react';
import { Descendant, Range } from 'slate';
import { useSlate } from 'slate-react';

import { OrderingEnum } from 'api/ordering/types';
import useGetGroupPolicy from 'api/useGetGroupPolicy';
import { Button } from 'components/buttons';
import Radio from 'components/buttons/radioButton';
import Checkbox from 'components/checkbox';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from 'components/dialogs/components/table/Table';
import Dialog from 'components/dialogs/DialogBuilder';
import { PopoverColors } from 'components/editMdfDialog/components/PopoverColors';
import { EditorVariant } from 'components/editor/types';
import Infobar from 'components/infobar';
import { useCreateScopedConfig } from 'components/scopedConfigs/useCreateScopedConfig';
import { useUpdateScopedConfig } from 'components/scopedConfigs/useUpdateScopedConfig';
import Text from 'components/text';
import useToast from 'components/toast/useToast';
import Tooltip from 'components/tooltip';
import { Color, StyledTextField } from 'features/reusableStyled';
import { useCanAdministrateSnippets } from 'hooks/useCheckUserRight';
import { Box, HStack, VStack } from 'layouts/box/Box';
import { MemberType, MemberTypeEnum, SavedSearchType, ScopedConfig } from 'types/graphqlTypes';

import type { GroupPolicy } from '../../../command/scopedConfigs/ScopedConfigs';
import ScopedConfigs from '../../../command/scopedConfigs/ScopedConfigs';
import { CheckboxWrapper, ColorWrapper, StyledText } from '../../../command/scopedConfigs/styled';

import { snippetLocations } from './constants';

export interface SnippetData {
  slashCommand: string;
  slateData: Descendant[];
}

type VisibilityTypes = SavedSearchType | 'all';

const getVisibleTo = (type: VisibilityTypes, groups?: string[]) => {
  if (type === 'all' || type === 'private') return [];
  return groups ?? [];
};

const getVisibleIn = (visibleIn: string[] | undefined) => {
  if (visibleIn === undefined || visibleIn.length === 0) return;
  return visibleIn;
};

interface ScopedSnippetDialogProps {
  currentLocation: EditorVariant;
  onClose: () => void;
  managingSnippets: boolean;
  setManagingSnippets: (val: boolean) => void;
}

const LayoutHeader = () => {
  return <div />;
};

const toTypedGroupPolicy = (groups: MemberType[] | undefined): GroupPolicy[] => {
  return (groups ?? []).map((g) => {
    return {
      id: g.mRefId!,
      label: g.mTitle!,
    };
  });
};

const getHeader = (isManaging: boolean, isEditing: boolean) => {
  if (isManaging) return 'Managing snippets';
  return isEditing ? 'Editing snippet' : 'Create new';
};

const getConfirmLabel = (isManaging: boolean, isEditing: boolean) => {
  if (isManaging) return 'Close';
  return isEditing ? 'Update' : 'Create';
};

/**
 * This component is designed to only render when using it
 */
export default function ScopedInnerSnippetDialog({
  currentLocation,
  managingSnippets,
  onClose,
  setManagingSnippets,
}: Readonly<ScopedSnippetDialogProps>) {
  const editor = useSlate();
  const hasSelection = editor.selection && !Range.isCollapsed(editor.selection);
  const { canAdministrate } = useCanAdministrateSnippets();

  const { groupPolicies } = useGetGroupPolicy();
  const groups = useMemo(() => {
    return toTypedGroupPolicy(groupPolicies);
  }, [groupPolicies]);
  const { errorToast, successToast } = useToast();
  const { createScopedConfig } = useCreateScopedConfig(currentLocation, canAdministrate);
  const { updateScopedConfig } = useUpdateScopedConfig<SnippetData>(canAdministrate);

  const [type, setType] = useState<VisibilityTypes>('private');
  const [label, setLabel] = useState('New snippet');
  const [shortcut, setShortcut] = useState('new');
  const [color, setColor] = useState<string | undefined>();
  const [visibleIn, setVisibleIn] = useState<string[] | undefined>([currentLocation]);
  const [userGroups, setUserGroups] = useState<string[] | undefined>();
  const [updateSelection, setUpdateSelection] = useState(false);

  const [editingSnippet, setEditingSnippet] = useState<ScopedConfig<SnippetData> | null>(null);

  const onUpdate = () => {
    if (editingSnippet) {
      updateScopedConfig({
        ...editingSnippet,
        data: {
          slashCommand: shortcut,
          slateData: updateSelection ? editor.getFragment() : editingSnippet.data.slateData,
        },
        type: type === 'all' ? 'shared' : type,
        label,
        color,
        visibleTo: getVisibleTo(type, userGroups),
        visibleIn: getVisibleIn(visibleIn) ?? [],
      })
        .then(() => successToast(`${label} updated`))
        .catch(errorToast)
        .finally(() => onClose());
    }
  };

  const onSaveNew = () => {
    const data: SnippetData = {
      slashCommand: shortcut,
      slateData: editor.getFragment(),
    };
    createScopedConfig({
      data: JSON.stringify(data),
      label,
      color,
      mType: MemberTypeEnum.Snippet,
      type: type === 'all' ? 'shared' : type,
      visibleTo: getVisibleTo(type, userGroups),
      visibleIn: getVisibleIn(visibleIn),
    })
      .then(() => successToast(`New ${label} saved`))
      .catch(errorToast)
      .finally(() => onClose());
  };

  const onGroupChange = useCallback(
    (group: GroupPolicy) => {
      if (userGroups?.includes(group.id)) {
        setUserGroups((userGroups ?? []).filter((g) => g !== group.id));
      } else {
        setUserGroups((prevValue) => {
          return [...(prevValue ?? []), group.id];
        });
      }
    },
    [userGroups, setUserGroups],
  );

  const disabledTooltip = useMemo(() => {
    if (!hasSelection) return 'No valid text selected';
    if (type === 'private' || type === 'all') return '';
    if (userGroups === undefined || userGroups.length === 0) return '';
    if (userGroups.length === 0) return 'At least one group must be chosen';
    return '';
  }, [userGroups, type, visibleIn, hasSelection]);

  const onVisibilityChange = useCallback(
    (newPlace: string) => {
      setVisibleIn((prevValue) => {
        const newValues = prevValue ?? [];
        if (newValues.includes(newPlace)) {
          return [...newValues.filter((v) => v !== newPlace)];
        } else {
          return [...newValues, newPlace];
        }
      });
    },
    [setVisibleIn],
  );

  const onTypeChange = useCallback(
    (newType: VisibilityTypes) => {
      if (newType === 'private') {
        setType('private');
        setUserGroups([]);
      }

      if (canAdministrate) {
        if (newType === 'all') {
          setUserGroups([]);
        } else {
          setUserGroups(groups.map((g) => g.id));
        }
        setType(newType);
      }
    },
    [canAdministrate, setType],
  );

  const confirmButtonDisabled = label.length === 0 || disabledTooltip.length > 0;

  const onKeyDown = useCallback(
    (ev: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      if (ev.key === 'Enter') {
        // We stop propagation regardless, enter will otherwise potentially trigger
        // an open event on the selected search item behind this dialog if
        // dina command bar is up
        ev.preventDefault();
        ev.stopPropagation();
        if (!confirmButtonDisabled) onSaveNew();
      }
    },
    [label, disabledTooltip],
  );

  const onOpenExisting = (val: ScopedConfig<SnippetData>) => {
    setEditingSnippet(val);
    setManagingSnippets(false);
    setType(val.type);
    setLabel(val.label);
    setShortcut(val.data.slashCommand);
    setColor(val.color);
    setVisibleIn(val.visibleIn);
  };

  const doConfirm = useCallback(() => {
    if (editingSnippet) {
      onUpdate();
    } else {
      onSaveNew();
    }
  }, [editingSnippet, onUpdate, onSaveNew]);

  return (
    <>
      <Dialog.Header>
        <HStack width="100%" justifyContent="space-between">
          <Text>{getHeader(managingSnippets, editingSnippet !== null)}</Text>
          <Button
            width={managingSnippets ? 90 : 120}
            variant="outlined"
            usage="outlined"
            onClick={() => setManagingSnippets(!managingSnippets)}
          >
            {managingSnippets ? 'Create new' : 'Manage existing'}
          </Button>
        </HStack>
      </Dialog.Header>
      <Dialog.Body bodyHeight="400px" padding="4px 10px">
        {managingSnippets ? (
          <ScopedConfigs<SnippetData>
            hideEdit
            selected={null}
            label="Snippets"
            location="default"
            mType={MemberTypeEnum.Snippet}
            newState={null}
            typeLabel="Snippet"
            smallTypeLabel="snippet"
            stateLabel="selected text"
            ordering={OrderingEnum.defaultSnippet}
            onApply={onOpenExisting}
            Header={LayoutHeader}
            canAdministrate={canAdministrate}
          />
        ) : (
          <Box overflow="auto" height="100%">
            <Dialog.Group noMargin>
              <Dialog.Label>Name</Dialog.Label>
              <Box width="100%" padding="0 0 0 4px">
                <StyledTextField
                  fullWidth
                  variant="filled"
                  value={label}
                  onFocus={(ev) => ev.currentTarget.select()}
                  onChange={(e) => setLabel(e.currentTarget.value)}
                  inputProps={{
                    onKeyDown: (ev) => onKeyDown(ev),
                    autoFocus: true,
                    onFocus: (ev) => ev.currentTarget.select(),
                  }}
                />
              </Box>
            </Dialog.Group>
            <Dialog.Group>
              <Dialog.Label>Shortcut</Dialog.Label>
              <Box width="100%" padding="0 0 0 4px">
                <StyledTextField
                  fullWidth
                  variant="filled"
                  value={shortcut}
                  onFocus={(ev) => ev.currentTarget.select()}
                  onChange={(e) => setShortcut(e.currentTarget.value)}
                  inputProps={{
                    onFocus: (ev) => ev.currentTarget.select(),
                  }}
                  helperText="Slash command (e.g. 'slc')"
                />
              </Box>
            </Dialog.Group>
            {editingSnippet !== null && (
              <Dialog.Group>
                <Dialog.Label>Update to current selection?</Dialog.Label>
                <CheckboxWrapper>
                  <Checkbox
                    selected={updateSelection}
                    onClick={() => setUpdateSelection(!updateSelection)}
                  />
                  <Text
                    variant="listItemLabel"
                    className="checkbox-label"
                    onClick={() => setUpdateSelection(!updateSelection)}
                  >
                    Update to current selected text?
                  </Text>
                </CheckboxWrapper>
              </Dialog.Group>
            )}
            <Dialog.Group>
              <Dialog.Label>Visible to</Dialog.Label>
              <HStack onClick={() => onTypeChange('private')} margin="0 0 4px 10px" gap="6px">
                <Radio selected={type === 'private'} size={20} />
                <StyledText variant="listItemLabel" style={{}}>
                  Me
                </StyledText>
              </HStack>
              <Tooltip
                title={!canAdministrate ? 'You do not have permission to manage snippets' : ''}
              >
                <HStack onClick={() => onTypeChange('all')} margin="0 0 4px 10px" gap="6px">
                  <Radio selected={type === 'all'} size={20} disabled={!canAdministrate} />
                  <StyledText
                    variant="listItemLabel"
                    color={!canAdministrate ? 'disabled' : undefined}
                  >
                    Everyone
                  </StyledText>
                </HStack>
              </Tooltip>
              <Tooltip
                title={!canAdministrate ? 'You do not have permission to manage snippets' : ''}
              >
                <HStack onClick={() => onTypeChange('shared')} margin="0 0 8px 10px" gap="6px">
                  <Radio selected={type === 'shared'} size={20} disabled={!canAdministrate} />
                  <StyledText
                    variant="listItemLabel"
                    color={!canAdministrate ? 'disabled' : undefined}
                  >
                    Specific groups
                  </StyledText>
                </HStack>
              </Tooltip>
              {type === 'shared' && (
                <>
                  <Box maxHeight="40vh" width="100%" padding="0 0 8px 0" overflow="auto">
                    <Table>
                      <TableHeader>
                        <TableRow>
                          <TableHead>Group</TableHead>
                          <TableHead>Shared with</TableHead>
                        </TableRow>
                      </TableHeader>
                      <TableBody>
                        {groups.map((p) => (
                          <TableRow key={p.id}>
                            <TableCell>
                              <StyledText variant="listItemLabel" onClick={() => onGroupChange(p)}>
                                {p.label}
                              </StyledText>
                            </TableCell>
                            <TableCell>
                              <Checkbox
                                selected={userGroups?.length === 0 || userGroups?.includes(p.id)}
                                onClick={() => onGroupChange(p)}
                              />
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </Box>
                  {canAdministrate && (
                    <Infobar>
                      Due to having permission to manage snippets, you will always see all global
                      snippets.
                    </Infobar>
                  )}
                </>
              )}
            </Dialog.Group>
            <Dialog.Group>
              <Dialog.Label>Available in</Dialog.Label>
              <VStack maxHeight="50vh" width="100%" alignItems="start">
                {snippetLocations.map((p) => (
                  <HStack key={p.type} justifyContent="start" height="24px">
                    <Checkbox
                      selected={visibleIn?.includes(p.type)}
                      onClick={() => onVisibilityChange(p.type)}
                    />
                    <div>
                      <StyledText
                        variant="listItemLabel"
                        onClick={() => onVisibilityChange(p.type)}
                      >
                        {p.label}
                      </StyledText>
                    </div>
                  </HStack>
                ))}
              </VStack>
            </Dialog.Group>
            <Dialog.Group>
              <Dialog.Label>Look &amp; feel</Dialog.Label>
              <PopoverColors
                onColorChoice={(c: string) => setColor(c)}
                onClearColor={() => setColor(undefined)}
                selectedColor={color}
              >
                <ColorWrapper padding="6px 0 6px 6px" gap="6px">
                  <Color $color={color} $size={16} />
                  <Text variant="listItemLabel" className="chckbox-label">
                    Set a color
                  </Text>
                </ColorWrapper>
              </PopoverColors>
            </Dialog.Group>
          </Box>
        )}
      </Dialog.Body>
      <Dialog.Footer>
        <Dialog.CancelButton />
        <Dialog.ConfirmButton
          label={getConfirmLabel(managingSnippets, editingSnippet !== null)}
          title={label.length === 0 ? 'Name required' : disabledTooltip}
          onConfirm={doConfirm}
          disabled={confirmButtonDisabled}
        />
      </Dialog.Footer>
    </>
  );
}
