import { useMemo } from 'react';
import { useQuery } from '@apollo/client';

import { OrderingEnum } from 'api/ordering/types';
import { useGetOrdering } from 'api/ordering/useGetOrdering';
import { SnippetData } from 'components/editor/components/snippets/InnerSnippetDialog';
import { GET_SCOPED_CONFIGS } from 'components/scopedConfigs/gql';
import { useCanAdministrateSnippets } from 'hooks/useCheckUserRight';
import { MemberTypeEnum, ScopedConfig, ScopedConfigEntity } from 'types/graphqlTypes';
import { sortByOrdering } from 'utils/sortByOrder';

export interface GetScopedConfigs {
  getScopedConfigs: ScopedConfigEntity[];
}

const toSnippetDto = (s: ScopedConfigEntity): ScopedConfig<SnippetData | null> => {
  try {
    return {
      ...s,
      visibleIn: s.visibleIn ?? [],
      visibleTo: s.visibleTo ?? [],
      data: JSON.parse(s.data ?? '{}') as SnippetData,
    };
  } catch {
    return {
      ...s,
      visibleIn: s.visibleIn ?? [],
      visibleTo: s.visibleTo ?? [],
      data: null,
    };
  }
};

export const useGetSnippets = (location: string) => {
  const { canAdministrate } = useCanAdministrateSnippets();

  const { ordering, loading: orderingLoading } = useGetOrdering(OrderingEnum.defaultSnippet);
  const { data, error, loading, refetch } = useQuery<GetScopedConfigs>(GET_SCOPED_CONFIGS, {
    fetchPolicy: 'cache-and-network',
    variables: {
      input: {
        all: canAdministrate,
        mType: MemberTypeEnum.Snippet,
        location,
      },
    },
  });

  const snippets = useMemo(() => {
    if (orderingLoading || loading) return [];
    const items = data?.getScopedConfigs?.map((e) => toSnippetDto(e)) ?? [];
    return sortByOrdering<ScopedConfig<SnippetData | null>>(items, ordering?.order ?? []);
  }, [data, ordering, orderingLoading, loading, location]);

  return {
    snippets,
    error,
    loading,
    refetch,
  };
};
