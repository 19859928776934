/* eslint-disable sort-imports */
import { memo, ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { createPortal } from 'react-dom';
import { BaseRange } from 'slate';

import { useGetSnippets } from 'components/editor/components/snippets/hooks/useGetSnippets';
import { useEditorContext } from 'components/editor/EditorContext';
import { useEditorMolecule } from 'components/editor/store';
import { EditorVariant } from 'components/editor/types';
import Text from 'components/text/Text';
import { getSupportedResourceType } from 'features/sidepanel/utils/utils';
import useFuseSearch from 'hooks/useFuseSearch';
import { Box } from 'layouts/box/Box';
import { useEditorCommands } from 'store';
import { MemberTypeEnum } from 'types/graphqlTypes';
import { EditorCommandConfigType } from 'types/memberTypes/editorCommands';
import preventDefaultAndPropagation from 'utils/preventDefaultAndStopPropagation';

import useCombobox, { Position } from '../../hook/useCustomCombobox';
import useInsertBlock from '../../hook/useInsertBlock';

import { Info, List, ListWrapper, MenuItem, SnippetIcon, TaskIcon } from './styled';

const beforeRegex = /(?:^\/)|(?:\/$)/;
const afterRegex = /^(\s|$)/;

function Portal({ children }: { children: ReactNode }) {
  return createPortal(children, document.body);
}

function UserCommands({
  readOnly,
  editorVariant,
}: Readonly<{ readOnly: boolean; editorVariant: EditorVariant }>) {
  const [search, setSearch] = useState('');
  const { useCommandTarget } = useEditorMolecule();
  const [target, setTarget] = useCommandTarget();
  const [allEditorCommands] = useEditorCommands();
  const { snippets } = useGetSnippets(editorVariant);

  const { resourceDetails } = useEditorContext();
  const fuseSearch = useFuseSearch({
    shouldSort: true,
  });

  const { insertMdfBlock, insertOrderBlock, insertSnippet } = useInsertBlock(
    search,
    resourceDetails,
  );

  // For simplicity in logic downstream, normalize new snippet data structure into
  // editor command type
  const snippetCommands: EditorCommandConfigType[] = useMemo(() => {
    return snippets.map((s) => {
      return {
        mColor: s.color,
        mTitle: s.label,
        mId: s.id,
        mResourceType: MemberTypeEnum.Snippet,
        slashCommand: s.data?.slashCommand ?? '',
      } as EditorCommandConfigType;
    });
  }, [snippets]);

  const mergedCommands = useMemo(() => {
    if (editorVariant === 'dailyNote' || editorVariant === 'script') return snippetCommands;
    return [...allEditorCommands, ...snippetCommands];
  }, [allEditorCommands, snippetCommands, editorVariant]);

  const { resource, orderFormMap } = resourceDetails ?? {};

  const editorCommands = useMemo(() => {
    const formTypes = resource ? getSupportedResourceType(resource) : null;
    const filteredCommands = mergedCommands.filter((editorCmd) => {
      if (editorCmd.mResourceType === MemberTypeEnum.Snippet) return true;
      if (!formTypes) return false;
      if (editorCmd.mResourceType === MemberTypeEnum.Mdf) return editorCmd.mActive;
      const orderForm = orderFormMap?.[editorCmd.mTertId];
      if (orderForm?.configs) {
        const allowedTypes =
          orderForm.configs.find((config) => config.key === 'types')?.values ?? [];
        return editorCmd.mActive && allowedTypes.some((type) => formTypes.includes(type));
      }
      return false;
    });

    return filteredCommands;
  }, [mergedCommands, orderFormMap, resource]);

  const matchFunction = useCallback(
    (list: EditorCommandConfigType[]) =>
      fuseSearch(list, ['mTitle', 'slashCommand'], search?.substring(1) || ''),
    [fuseSearch, search],
  );

  const filteredCommands = useMemo(
    () => matchFunction(editorCommands).sort((a, b) => a.mTitle.localeCompare(b.mTitle)),
    [matchFunction, editorCommands],
  );

  const insertCommand = useCallback(
    (command: EditorCommandConfigType, targetNode: BaseRange | null) => {
      if (!targetNode) return;

      switch (command.mResourceType) {
        case MemberTypeEnum.OrderForm:
          return insertOrderBlock(command, targetNode);
        case MemberTypeEnum.Mdf:
          return insertMdfBlock(command, targetNode);
        case MemberTypeEnum.Snippet: {
          const snip = snippets.find((s) => s.id === command.mId);
          if (snip?.data?.slateData) {
            return insertSnippet(snip.data?.slateData, targetNode);
          }
          break;
        }
        default:
          return;
      }
    },
    [insertOrderBlock, insertMdfBlock, insertSnippet],
  );

  const { position, cursor } = useCombobox(
    filteredCommands,
    insertCommand,
    setSearch,
    {
      beforeExp: beforeRegex,
      afterExp: afterRegex,
    },
    target,
    setTarget,
    false,
  );

  useEffect(() => {
    const targetElement = document.getElementById(filteredCommands[cursor]?.mRefId);
    targetElement?.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
  }, [cursor, filteredCommands]);

  const showSuggestion = !readOnly && target && filteredCommands.length > 0;

  const onClickInsert = useCallback(
    (event: React.MouseEvent<HTMLLIElement>, command: EditorCommandConfigType) => {
      preventDefaultAndPropagation(event);
      insertCommand(command, target);
      setTarget(null);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [target, insertCommand],
  );

  return showSuggestion ? (
    <Portal>
      <ListWrapper position={position as Position<string>} elevation={12}>
        <List disablePadding>
          <Box container justifyContent="flex-start" height="32px" padding="0 8px 0">
            <Text variant="caption" color="inactive">
              Commands
            </Text>
          </Box>
          {filteredCommands.map((command, index) => {
            const { mRefId, mTitle, slashCommand, mColor } = command;
            return (
              <MenuItem
                dense
                disableRipple
                selected={index === cursor}
                key={mRefId}
                id={mRefId}
                onClick={(event) => onClickInsert(event, command)}
                disableGutters
              >
                {mTitle && (
                  <Text variant="listItemLabel" color="highEmphasis">
                    {mTitle}
                  </Text>
                )}
                {command.mResourceType === MemberTypeEnum.OrderForm && (
                  <TaskIcon height={12} width={12} className="skipOverride" />
                )}
                <Info
                  $color={command.mResourceType === MemberTypeEnum.Snippet ? mColor : undefined}
                >{`/${slashCommand}`}</Info>
                {command.mResourceType === MemberTypeEnum.Snippet && (
                  <SnippetIcon height={16} width={16} className="skipOverride" $color={mColor} />
                )}
              </MenuItem>
            );
          })}
        </List>
      </ListWrapper>
    </Portal>
  ) : null;
}

export default memo(UserCommands);
