import { SPEAK_TIME_FIELD_KEY, TOTAL_TIME_FIELD_KEY } from 'features/mdf/mdf-defaults';
import { Metadata, MMetaDataFieldInput } from 'types/graphqlTypes';

import { durationTypes, getDuration, getSeconds, getTime } from '../components/editor/utils';
import {
  getClipDurationFromMetadata,
  getSpeakDurationFromMetadata,
  isTimingValue,
} from '../rundownV2/utils/timing';
import { RundownCellData } from '../rundownV2/utils/types';

import { RundownTimingFieldKeys } from './rundownTiming';
import { toNumber } from './typeUtils';

const updateDurationField = <T extends string | number>(
  fields: MMetaDataFieldInput[],
  key: string,
  value: T,
  canUpdateScriptDurationSettingsValue: boolean = false,
) => {
  const field = fields.find((f) => f.key.includes(key));
  const fieldKey = field ? field.key : key;

  const fieldList = fields.filter((f) => f.key !== fieldKey);

  if (fieldKey.includes(durationTypes.SPEAK_DURATION)) {
    fieldList.push({
      key: fieldKey,
      autoValue: value,
      value:
        !canUpdateScriptDurationSettingsValue || field?.manual === null || field?.manual === false
          ? value
          : field?.value,
      manual:
        field?.manual === null || !canUpdateScriptDurationSettingsValue ? false : field?.manual,
    });
  } else fieldList.push({ key: fieldKey, value });

  return fieldList;
};

export const respectHostReadSpeed = (
  mMetaData: MMetaDataFieldInput[] | undefined,
  hostReadSpeed: number | string,
  canUpdateScriptDurationSettingsValue = true,
) => {
  let metaData = mMetaData ? [...mMetaData] : [];
  const wordCount = toNumber(getDuration(metaData, durationTypes.WORD_COUNT), 0);
  if (!wordCount) return metaData;

  const readRate = toNumber(hostReadSpeed, 0); // words per minute
  const wordsPerSecond = readRate / 60;
  const newSpeakDuration = Math.ceil(wordCount / wordsPerSecond);

  metaData = updateDurationField(metaData, durationTypes.HOST_READ_SPEED, readRate);

  metaData = updateDurationField(
    metaData,
    durationTypes.SPEAK_DURATION,
    getTime(newSpeakDuration),
    canUpdateScriptDurationSettingsValue,
  );
  const clipDuration = getDuration(metaData, durationTypes.CLIP_DURATION);
  const scriptDuration = getDuration(metaData, durationTypes.SPEAK_DURATION);
  const newTotalDuration = getSeconds(clipDuration) + getSeconds(scriptDuration);

  metaData = updateDurationField(metaData, durationTypes.TOTAL_DURATION, getTime(newTotalDuration));

  return metaData;
};

/**
 * Handles storing the new updated values in the new metadata location
 */
export const respectHostReadSpeedV2 = (
  instance: RundownCellData,
  hostReadSpeed: number | string,
  presenterId?: string,
) => {
  const legacyMetaData: MMetaDataFieldInput[] = [];
  const wordCount = toNumber(instance.wordCount, 0);

  // Track presenter id in the instance
  legacyMetaData.push({
    key: RundownTimingFieldKeys.PRESENTER_ID,
    value: presenterId,
  });

  // If no word count we can skip recalculations
  if (!wordCount) {
    return { legacyMetaData, updatedMetadata: {} };
  }

  const readRate = toNumber(hostReadSpeed, 0); // words per minute
  const wordsPerSecond = readRate / 60;
  const newSpeakDuration = Math.ceil(wordCount / wordsPerSecond);

  const speakDuration = getSpeakDurationFromMetadata(instance.metadata);
  const clipDuration = getClipDurationFromMetadata(instance.metadata);
  const newTotalDuration = getTime(
    getSeconds(clipDuration?.autoValue ?? clipDuration?.value ?? '00:00') + newSpeakDuration,
  );

  // New value for host read speed
  legacyMetaData.push({
    key: RundownTimingFieldKeys.HOST_READ_SPEED,
    value: hostReadSpeed,
  });

  const speakTime = getTime(newSpeakDuration);
  // Update speak duration accordingly
  legacyMetaData.push({
    key: RundownTimingFieldKeys.SPEAK_DURATION,
    value: speakDuration?.manual ? speakDuration.value : speakTime,
    autoValue: speakTime,
    manual: speakDuration?.manual,
  });

  // Update total duration accordingly
  legacyMetaData.push({
    key: RundownTimingFieldKeys.TOTAL_DURATION,
    value: newTotalDuration,
    autoValue: newTotalDuration,
  });

  // For optimistic update. These values should, once legacy fields have been deprecated,
  // be written to the backend instead of the legacy metadata.
  const updatedMetadata: Metadata = {
    ...instance.metadata,
  };

  if (isTimingValue(updatedMetadata[SPEAK_TIME_FIELD_KEY])) {
    updatedMetadata[SPEAK_TIME_FIELD_KEY].autoValue = getTime(newSpeakDuration);
  } else {
    updatedMetadata[SPEAK_TIME_FIELD_KEY] = {
      value: getTime(newSpeakDuration),
      autoValue: getTime(newSpeakDuration),
      manual: false,
    };
  }
  if (isTimingValue(updatedMetadata[TOTAL_TIME_FIELD_KEY])) {
    updatedMetadata[TOTAL_TIME_FIELD_KEY].autoValue = newTotalDuration;
    updatedMetadata[TOTAL_TIME_FIELD_KEY].value = newTotalDuration;
  } else {
    updatedMetadata[TOTAL_TIME_FIELD_KEY] = {
      value: newTotalDuration,
      autoValue: newTotalDuration,
      manual: false,
    };
  }

  return { legacyMetaData, updatedMetadata };
};

export default respectHostReadSpeed;
