/* eslint-disable max-len */
import React, { ComponentType, useCallback, useMemo } from 'react';
import { capitalize, keyBy } from 'lodash';

import { OrderingEnum } from 'api/ordering/types';
import { useUpdateOrdering } from 'api/ordering/useUpdateOrdering';
import useGetGroupPolicy from 'api/useGetGroupPolicy';
import { ReactComponent as Add } from 'assets/icons/systemicons/add.svg';
import { IconButton } from 'components/buttons';
import LoadingIndicator from 'components/loadingIndicator/LoadingIndicator';
import { useDeleteScopedConfig } from 'components/scopedConfigs/useDeleteScopedConfig';
import { useGetScopedConfigs } from 'components/scopedConfigs/useGetScopedConfigs';
import SortableList, {
  getOrderFromSortableItems,
  RenderItemProps,
  SortableItems,
} from 'components/sortableList';
import useToast from 'components/toast/useToast';
import { useAsyncConfirmation } from 'hooks/useAsyncConfirmation';
import useMonitorSize from 'hooks/useMonitorSize';
import { Box, VStack } from 'layouts/box/Box';
import { MemberType, MemberTypeEnum, ScopedConfig, VisiblePlaces } from 'types/graphqlTypes';

import { CommandHeader } from '../styled';

import { useScopedConfigDialog } from './ScopedConfigDialog';
import ScopedConfigItem from './ScopedConfigItem';

import { StyledHeaderText } from './styled';

export interface GroupPolicy {
  id: string;
  label: string;
}

export const toTypedGroupPolicy = (groups: MemberType[] | undefined): GroupPolicy[] => {
  return (groups ?? []).map((g) => {
    return {
      id: g.mRefId!,
      label: g.mTitle!,
    };
  });
};

export interface ScopedConfigHeaderProps {
  onClick: () => void;
}

interface ScopedConfigsProps<T> {
  selected: string | null;
  label: string;
  mType: MemberTypeEnum;
  ordering: OrderingEnum;
  onApply: (s: ScopedConfig<T>) => void;
  location: VisiblePlaces | 'default';
  typeLabel: string;
  smallTypeLabel: string;
  stateLabel: string;
  newState: T | null;
  hideVisibleIn?: boolean;
  canAdministrate?: boolean;
  hideEdit?: boolean;
  Header?: ComponentType<ScopedConfigHeaderProps>;
}

const breakPoints = {
  small: 110,
  medium: 200,
};

export default function ScopedConfigs<T>({
  selected,
  label,
  ordering,
  mType,
  onApply,
  location,
  hideVisibleIn,
  typeLabel,
  smallTypeLabel,
  stateLabel,
  newState,
  hideEdit,
  Header,
  canAdministrate,
}: Readonly<ScopedConfigsProps<T>>) {
  const [, setScopedConfigDialogState] = useScopedConfigDialog();
  const { groupPolicies } = useGetGroupPolicy();
  const { scopedConfigs, loading } = useGetScopedConfigs<T>(
    ordering,
    mType,
    location === 'default' ? undefined : location,
  );
  const { successToast, errorToast } = useToast();
  const confirmAsync = useAsyncConfirmation();
  const { deleteScopedConfig } = useDeleteScopedConfig(!!canAdministrate);
  const { updateOrdering } = useUpdateOrdering(ordering);
  const { ref, size } = useMonitorSize<HTMLDivElement>(breakPoints);

  const groups = useMemo(() => {
    return toTypedGroupPolicy(groupPolicies);
  }, [groupPolicies]);

  const map = useMemo(() => {
    return keyBy(scopedConfigs, (s) => s.id);
  }, [scopedConfigs]);

  const onKeyDown = useCallback(
    (ev: React.KeyboardEvent<HTMLElement>, s: ScopedConfig<T>) => {
      if (ev.key === 'Enter' || ev.key === 'Space') {
        ev.preventDefault();
        ev.stopPropagation();
        onApply(s);
      }
    },
    [onApply],
  );

  const updateOrder = useCallback(
    (newOrder: SortableItems) => {
      const order = getOrderFromSortableItems(newOrder);
      updateOrdering(order).catch(errorToast);
    },
    [updateOrdering],
  );

  const onDeleteClick = useCallback(
    (item: ScopedConfig<T>) => {
      confirmAsync(
        {
          title: 'Are you sure?',
          message: ` Are you sure you want to delete the ${label}? This is a shared ${label} and will no longer be available.\n\n
            Once deleted, it cannot be restored.`,
          usage: 'danger',
          confirmLabel: 'Confirm delete',
          cancelLabel: 'Cancel',
        },
        async () => {
          deleteScopedConfig(item.id, item.type, item.mType).catch(errorToast);
        },
      )
        .then((ok) => {
          if (ok) {
            successToast(`${label} removed.`);
          }
        })
        .catch(errorToast);
    },
    [label, deleteScopedConfig],
  );

  const onEditClick = useCallback(
    (item: ScopedConfig<T>) => {
      setScopedConfigDialogState({
        openDialog: true,
        canAdministrate: !!canAdministrate,
        currentLocation: location,
        data: newState,
        groups,
        mType,
        stateLabel,
        typeLabel,
        hideVisibleIn,
        configToUpdate: item,
      });
    },
    [groups, mType, stateLabel, typeLabel, hideVisibleIn, newState, location, canAdministrate],
  );

  const renderConfigItem = useCallback(
    (renderItemProps: RenderItemProps) => {
      const item = map[renderItemProps.id];
      if (!item) return null;
      return (
        <ScopedConfigItem<T>
          key={renderItemProps.id}
          item={item}
          parentSize={size}
          onClick={() => onApply(item)}
          selected={selected === item.id}
          onKeyDown={(ev) => onKeyDown(ev, item)}
          onDeleteClick={onDeleteClick}
          onEditClick={!hideEdit ? onEditClick : undefined}
          {...renderItemProps}
        />
      );
    },
    [selected, map, size, onKeyDown, onDeleteClick, onEditClick, onApply],
  );

  const onCreateNew = () => {
    setScopedConfigDialogState({
      openDialog: true,
      canAdministrate: !!canAdministrate,
      currentLocation: location,
      data: newState,
      groups,
      mType,
      stateLabel,
      typeLabel,
      hideVisibleIn,
    });
  };

  return (
    <>
      {Header ? (
        <Header onClick={onCreateNew} />
      ) : (
        <CommandHeader justifyContent="space-between">
          <StyledHeaderText variant="caption" $small={size === 'small'}>
            {size === 'small' ? capitalize(smallTypeLabel) : capitalize(typeLabel)}
          </StyledHeaderText>
          <IconButton
            title={`Save ${typeLabel} (ctrl / cmd + s)`}
            size={24}
            iconSize={20}
            usage="text"
            tabIndex={-1}
            onClick={onCreateNew}
            style={{ flexShrink: 0 }}
          >
            <Add />
          </IconButton>
        </CommandHeader>
      )}
      {scopedConfigs.length === 0 && loading ? (
        <Box width="100%" height="100%" position="relative">
          <LoadingIndicator />
        </Box>
      ) : (
        <VStack
          width="100%"
          overflow="auto"
          justifyContent="start"
          height="calc(100% - 32px)"
          ref={ref}
        >
          <SortableList
            order={scopedConfigs}
            updateOrder={updateOrder}
            direction="vertical"
            renderItem={renderConfigItem}
          />
        </VStack>
      )}
    </>
  );
}
