/* eslint-disable max-len */
import { useState } from 'react';
import { atom, useAtom } from 'jotai';

import Dialog from 'components/dialogs/DialogBuilder';
import { EditorVariant } from 'components/editor/types';

import ScopedInnerSnippetDialog from './InnerSnippetDialog';

interface ScopedSnippetDialog {
  openDialog: boolean;
  currentLocation: EditorVariant;
}

const defaults: ScopedSnippetDialog = {
  openDialog: false,
  currentLocation: 'general',
};

const dialogProps = atom<ScopedSnippetDialog>(defaults);
export const useScopedSnippetsDialog = () => useAtom(dialogProps);

export default function ScopedSnippetsDialog() {
  const [state, setState] = useScopedSnippetsDialog();
  const [managingSnippets, setManagingSnippets] = useState(false);

  const onClose = () => {
    setState({ ...defaults });
    setManagingSnippets(false);
  };

  // Keep a lot of code out of the render loop
  // if not open.
  if (!state.openDialog) {
    return null;
  }

  return (
    <Dialog
      open={true}
      style={{ width: managingSnippets ? '300px' : '400px' }}
      modal={false}
      onClose={onClose}
    >
      <ScopedInnerSnippetDialog
        {...state}
        managingSnippets={managingSnippets}
        onClose={onClose}
        setManagingSnippets={setManagingSnippets}
      />
    </Dialog>
  );
}
