import { useEffect } from 'react';
import { gql, useQuery } from '@apollo/client';

import { useEditorCommands } from 'store/editor';
import { EditorCommandConfigType } from 'types/memberTypes/editorCommands';

export const STANDARD_EDITOR_COMMAND_KEYS = `
  mId
  mRefId
  mSecId
  mTertId
  mType
  mResourceType
  mTitle
  mDescription
  mActive
  slashCommand
  singleOnly
  mIcon
  mColor
  canSchedule
  metadata
`;

export const getErrorMessage = <E, I>(err: E, input: I, type?: string): string =>
  `${type ?? ''} ${err instanceof Error ? err.message : ''} - input: ${JSON.stringify(input)}`;

const GET_EDITOR_COMMANDS = gql`
  query GetEditorCommands($input: GetMemberInput) {
    getMemberFromId(input: $input) {
      ${STANDARD_EDITOR_COMMAND_KEYS}
    }
  }
`;

export interface GetEditorCommandsType {
  getMemberFromId: EditorCommandConfigType[];
}

const useGetEditorCommands = () => {
  const [, setEditorCommands] = useEditorCommands();

  const { data, loading } = useQuery<GetEditorCommandsType>(GET_EDITOR_COMMANDS, {
    variables: {
      input: {
        mId: 'mdfBlock',
      },
    },
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    if (data?.getMemberFromId) setEditorCommands(data.getMemberFromId);
  }, [loading]);
};

export default useGetEditorCommands;
